import './ContactsRowComponent.css'
import { Link } from 'react-router-dom';

function ContactsRowComponent(props) {
  return (
    <tr className={"ContactsRow " + props.childClass}>
        <td>{props.userId}</td>
        <td>{props.userPhone}</td>
        <td>{props.userMail}</td>
        <td>{props.userName}</td>
        <td className="ColDate">{props.userDate.split(' ')[0]}</td>
        <td className="ColPrio">{props.userPrio}</td>
        <td className="ColSite" title={props.userSite}><i
            className={(props.moderation === "2" ? 'fa-solid fa-check TGreen' : 'fa-solid fa-xmark TRed')+(props.userSite ? '' : ' Hide')}></i>
            {!props.userSite ? '' : ((props.userSite.length > 20) ? (props.userSite.slice(0, 20) + '...') : props.userSite)}
        </td>
        <td>
            <Link className="ActionButton BBlue" to={"/crm_user/"+props.userId}><i className="fa-solid fa-book"></i></Link>
        </td>
    </tr>
  );
}
  
export default ContactsRowComponent;
  