import './LineCardRowComponent.css';
import LabelCardComponent from "../LabelCardComponent/LabelCardComponent"
import { useState } from 'react';
import { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import {Link} from "react-router-dom";
import {ROOT_DOMAIN_API} from "../../Constant";

function LineCardRowComponent(props) {
  const [motif, setMotif] = useState('None');
  const [dynamicClass, setDynamicClass] = useState('');
  useEffect(() => {
    if(props.action==="opportunities"){
      if(props.motif==="plusDe3EurosParJour"){
        setMotif("+3€ per day")
      }
  
      if(props.motif==="moinsDe1EuroPour200kVisitsSimilar"){
        setMotif("-1€ for 200k VU")
      }
    }
  }, [props.motif, props.action])

  const toggleCheckBox = function(log_crm){
  
    confirmAlert({
      title: 'Confirmation required',
      message: 'Are you sure to do this ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCard(log_crm)
        },
        {
          label: 'No'
        }
      ]
    });
  }

  const deleteCard = function(log_crm){
    if(props.action==='first_contact'){
      (async () => {
        try {
          await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateSiteCheckCrmFirstContact", {id: props.id, log_crm: log_crm});
          setDynamicClass(" Hide");
          props.refresh();
        } catch (error) {
            console.error(error);
        }
      })();
    }

    if(props.action==='important_detag'){
      (async () => {
        try {
          await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateStatusDetagage", {id: props.id, log_crm: log_crm});
          setDynamicClass(" Hide")
        } catch (error) {
            console.error(error);
        }
      })();
    }

    if(props.action==='opportunities'){
      (async () => {
        try {
          await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateCheckCrmOpportunies", {id: props.id, log_crm: log_crm});
          setDynamicClass(" Hide")
        } catch (error) {
            console.error(error);
        }
      })();
    }

    if(props.action==='reminders'){
      (async () => {
        try {
          await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateReminderCheckCrm", {id: props.id, log_crm: log_crm});
          setDynamicClass(" Hide")
        } catch (error) {
            console.error(error);
        }
      })();
    }

    if(props.action==='new_active'){
      (async () => {
        try {
          await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateSiteCheckCrmNewActive", {id: props.id, log_crm: log_crm});
          setDynamicClass(" Hide")
        } catch (error) {
            console.error(error);
        }
      })();
    }

    if(props.action==='inactive'){
      (async () => {
        try {
          await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateSiteCheckCrmInactive", {id: props.id, log_crm: log_crm});
          setDynamicClass(" Hide")
        } catch (error) {
            console.error(error);
        }
      })();
    }

    if(props.action==='welcome_call'){
      (async () => {
        try {
          await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateSiteCheckCrmWelcomeCall", {id: props.id, log_crm: log_crm});
          setDynamicClass(" Hide")
        } catch (error) {
            console.error(error);
        }
      })();
    }

    if(props.action==='retag'){
      (async () => {
        try {
          await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateSiteCheckCrmRetag", {id: props.id, log_crm: log_crm});
          setDynamicClass(" Hide")
        } catch (error) {
            console.error(error);
        }
      })();
    }

    
  }

  return (
    <div className={"LineCardRow FlexCol " + props.childClass + dynamicClass}>
      <div className="FlexRow JustifyBetween AlignCenter">
        <div className="FlexCol JustifyBetween">
          <div className="FlexRow">
            <a target="blank" href={"https://" + props.title} className="ALink URed TBlack">
              <h2 className="M0">{(props.siteId ? (props.siteId + ' | ' + props.title) : props.title)}</h2>
            </a>
            <LabelCardComponent childClass="ML10" icon="fa-solid fa-earth-americas" color="BRed" text={props.plateform}/>
          </div>
          {props.site_vu > 0 ? <div className="FlexRow">
            <p className="M0"><span>VU Similar : {props.site_vu}</span></p>
          </div> : ''}
          <div className="FlexRow FlexWrap MT10">
            {props.action === "opportunities" ?
                <LabelCardComponent childClass="MR10 MB10" icon="" color="BPurple" text={motif}/> : ''}
            {props.prio ?
                <LabelCardComponent childClass="MR10 MB10" icon="fa-solid fa-arrow-up-short-wide" color="BOrange" text={props.prio}/> : ''}
              { props.adm_name ? <LabelCardComponent childClass="MR10 MB10" icon="fa-regular fa-user" color="BGreen" text={props.adm_name}/> : ''}
              { /* props.site_date ? <LabelCardComponent childClass="MR10 MB10" icon="fa-regular fa-calendar" color="BPurple" text={props.site_date}/> : '' */}
              {/*{ props.site_vu ? <LabelCardComponent childClass="MR10 MB10" icon="fa-solid fa-eye" color="BPurple" text={props.site_vu}/> : ''}*/}
              { !props.child ? <LabelCardComponent childClass="MR10 MB10" icon="fa-solid fa-baby" color="BPink" text={"Child"}/> : <LabelCardComponent childClass="MR10 MB10" icon="fa-solid fa-person" color="BPink" text={"Parent"}/>}
              { /* props.siteDate ? <LabelCardComponent childClass="MR10 MB10" icon="fa-regular fa-calendar" color="BGreen" text={props.siteDate.split(' ')[0]}/> : '' */}
              { props.user_pros_name ? <LabelCardComponent childClass="MR10 MB10" icon="fa-solid fa-hammer" color="BGreen" text={props.user_pros_name}/> : '' }
              { /* props.top_geo ? <LabelCardComponent childClass="MR10 MB10" icon="fa-solid fa-info" color="BPink" text={props.top_geo}/> : '' */ }
              {/*<LabelCardComponent childClass="MR10 MB10" icon="fa-solid fa-earth-americas" color="BRed" text={props.plateform}/>*/}
              { props.piracy ? <LabelCardComponent childClass="MR10 MB10" color="BRed" text={props.piracy}/> : '' }
              { props.pop_sourced ? <LabelCardComponent childClass="MR10 MB10" color="BRed" text={props.pop_sourced}/> : '' }
              { props.embedded ? <LabelCardComponent childClass="MR10 MB10" color="BRed" text={props.embedded}/> : '' }
              { props.MFA ? <LabelCardComponent childClass="MR10 MB10" color="BOrange" text={props.MFA}/> : '' }
              { props.pop_creator ? <LabelCardComponent childClass="MR10 MB10" color="BOrange" text={props.pop_creator}/> : '' }
              { props.new_reg_flag ? <LabelCardComponent childClass="MR10 MB10" color="BOrange" text={props.new_reg_flag}/> : '' }
              { props.wp_version ? <LabelCardComponent childClass="MR10 MB10" color="BOrange" icon="fa-brands fa-wordpress" text={props.wp_version}/> : '' }
              <LabelCardComponent childClass="MR10 MB10" icon="fa-solid fa-file" color="BPurple" text={'Ads.txt ' + props.ads_txt}/>
              <LabelCardComponent childClass="MR10 MB10" icon="fa-solid fa-file" color="BPurple" text={'CMP ' + props.cmp}/>
            </div>
            <p className="M0">Name: <span>{props.name}</span></p>
            <p className="M0">Email: <span>{props.email}</span></p>
            {/* <p className="M0">Tel: <span>{props.phone}</span></p> */}
            { props.desc_reminder ? <p className="M0">Desc: <span>{props.desc_reminder}</span></p> : '' }
        </div>
        <div className="FlexCol">
            {props.siteId ? <a className="ActionButton BPurple MB5" target="blank" href={"https://bo.themoneytizer.com/statistics/" + props.siteId}><i className="fa-solid fa-chart-line"></i></a> : ''}
            <Link className="ActionButton BBlue MB5" target="_blank" to={"/crm_user/"+props.userId}><i className="fa-solid fa-book"></i></Link>
            <button className="ActionButton BGreen MB5" onClick={() => toggleCheckBox(1)}><i className="fa-solid fa-check"></i></button>
            <button className="ActionButton BRed" onClick={() => toggleCheckBox(0)}><i className="fa-solid fa-xmark"></i></button>
        </div>
      </div>
    </div>
  );
}

export default LineCardRowComponent;
