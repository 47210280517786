import './BillsComponent.css';
import BillsRowComponent from '../BillsRowComponent/BillsRowComponent'

function BillsComponent(props) {
    return (
        <div className={"UsersBills " + props.childClass} id="UsersBills">
            <h3>User's bills</h3>
            <div className="UsersBillsTable">
                <table>
                    <thead>
                        <tr>
                        <th>Bill ID</th>
                        <th>Date</th>
                        <th>Payment date</th>
                        <th>Amount</th>
                        <th>Payment type</th>
                        <th>State</th>
                        <th>Error</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.listBills.map((row, key) => {
                            return <BillsRowComponent bill_id={row.bill_id}
                                key={key}
                                bill_date={row.bill_date} bill_amount={row.bill_amount}
                                bill_amount_dollar={row.bill_amount_dollar} bill_currency={row.bill_currency}
                                bill_exceptional_dollar={row.bill_exceptional_dollar}
                                bill_exceptional = {row.bill_exceptional}
                                user_net30={row.bill_user_net30} paypal_mail={row.bill_user_paypal_mail}
                                payment_state={row.ptp_state} payment_error={row.ptp_error_message}
                                />
                                })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
  
export default BillsComponent;