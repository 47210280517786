import './TicketComponent.css';
import axios from "axios";
import { useState, useEffect } from "react";
import TicketRowComponent from '../TicketRowComponent/TicketRowComponent';
import { ROOT_DOMAIN_API } from "../../Constant";

function TicketComponent(props) {
    const [ticketsList, setTicketsList] = useState([]);

  useEffect(() => {
    (async () => {
        try {
          const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getOpenTickets");
          const obj = await resp.data;
          if(obj.results&&obj.results.open_tickets!==null){
            setTicketsList(obj.results.open_tickets);
          }
        } catch (error) {
            console.error(error);
        }
      })();    
  }, []);

  return (
    <div className={"Ticket FlexCol " + props.childClass}>
        <h2>My open tickets</h2>
        <div className="ContainerTicketTable">
          <table className="TicketTable">
              <thead>
              <tr>
                  <th>Ticket ID</th>
                  <th>Object</th>
                  <th>Priority</th>
                  <th>Tag</th>
                  <th>Time opened</th>
              </tr>
              </thead>
              <tbody>
              {ticketsList.map((row, index) => {
                  return <TicketRowComponent key={index} id={row.id} prio={row.priority} subject={row.subject} tags={row.tags[0]} opened={row.time_opened} />
              })}
              </tbody>
          </table>
        </div>
    </div>
  );
}

export default TicketComponent;
