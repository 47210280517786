import LabelCardComponent from '../LabelCardComponent/LabelCardComponent';
import './TicketRowComponent.css'

function TicketRowComponent(props) {
  return (
    <tr className="TicketRow">
        <td><a className="ActionLink" target="blank" href={"https://themoneytizer.freshdesk.com/a/tickets/" + props.id}>{props.id}</a></td>
        <td title={props.subject}>{props.subject.slice(0, 30) + '...'}</td>
        <td>{props.prio}</td>
        <td>
          <LabelCardComponent text={props.tags} color={"BRed"} childClass={props.tags ? '' : 'Hide'} icon="fa-regular fa-flag"/>
          <div className={"Holder" + (props.tags ? 'Hide' : '')}></div>
        </td>
        <td>{props.opened}</td>
    </tr>
  );
}

export default TicketRowComponent;
