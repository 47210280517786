import './TimeLineRowComponent.css'
import LabelCardComponent from '../LabelCardComponent/LabelCardComponent'
import ModalComponent from '../ModalComponent/ModalComponent';
import { useState } from 'react';

function TimeLineRowComponent(props) {

  const [modalVisibility, setModalVisibility] = useState(false);
  const toggleModalVisibility = function() {
    if(modalVisibility){
      setModalVisibility(false);
    } else {
      setModalVisibility(true);
    }
  }
  return (
    <div className={"FlexCol TimeLineRow "}>
      <ModalComponent modalVisibility={modalVisibility} toggleModalVisibility={toggleModalVisibility} title={props.title} unsafeHTML={props.html}/>
        <div className="FlexRow FlexSpaceAround">Title: {props.title}
          <div className='FlexCol'>
              <LabelCardComponent color={props.color} text={props.text.replace('_', ' ')} icon={props.icon}/>
          </div>
        </div>
        <div>From: {props.from}</div>
        <div className={props.name ? "" : "Hide"}>Tags: {props.name}</div>
        <div className="FlexRow FlexSpaceAround">Date: {props.date}
          <span>
            <a className={"ActionLink " + (props.link ? "" : "Hide")} target="blank" href={props.link}>Link</a>
            <button className={"ActionButton ML20 " + (props.html ? "" : "Hide")} onClick={toggleModalVisibility} >Preview</button>
          </span>
        </div>
    </div>
  );
}

export default TimeLineRowComponent;