import './DateComponent.css'
import { useState, useEffect } from 'react';
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../Constant";

function DateComponent(props) {
    const optionsDate = dateRange("2023-01-01", (new Date().toISOString().split("T")[0]));
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);

    useEffect(() => {
        if(!selectedDate){
            return;
        }

        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getCountValidatedWebsites?date="+selectedDate);
                const obj = await resp.data;
                if(obj.results!=null){
                    props.setAmountValidatedWebsite(obj.results);
                }
            } catch (error) {
                console.error(error);
            }

            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_activeWebsite?date="+selectedDate);
                const obj = await resp.data;
                if(obj.results!=null){
                    props.setAmountActiveWebsite(obj.results);
                }
            } catch (error) {
                console.error(error);
            }

            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_averageCA?date="+selectedDate);
                const obj = await resp.data;
                if(obj.results!=null){
                    props.setAverageCA(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    function dateRange(startDate, endDate) {
        var start      = startDate.split('-');
        var end        = endDate.split('-');
        var startYear  = parseInt(start[0]);
        var endYear    = parseInt(end[0]);
        var dates      = [];

        for(var i = startYear; i <= endYear; i++) {
            var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
            var startMon = i === startYear ? parseInt(start[1])-1 : 0;
            for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
                var month = j+1;
                var displayMonth = month < 10 ? '0'+month : month;
                dates.push([i, displayMonth, '01'].join('-'));
            }
        }
        dates.reverse();
        return dates;
    }

    return (
        <select style={{padding: "5px"}} onChange={(event) => {
            setSelectedDate(event.target.value)
        }}>
            {optionsDate.map((row, key) => {
                return <option value={row}>{(row.split('-')[0] + "-" + row.split('-')[1])}</option>
            })}
        </select>
    );
}

export default DateComponent;