import LateralNavComponent from "./LateralNavComponent/LateralNavComponent";
import CardProgressComponent from "./CardProgressComponent/CardProgressComponent";
import './Home.css';
import axios from "axios";
import { useState, useEffect } from "react";
import DualCardComponent from "./DualCardComponent/DualCardComponent";
import HeadLineComponent from "./HeadLineComponent/HeadLineComponent";
import { ToastContainer } from "react-toastify";
import SiteStateComponent from "./SiteStateComponent/SiteStateComponent";
import FreshdeskChartComponent from "./FreshdeskChartComponent/FreshdeskChartComponent";
import AuthComponent from "./AuthComponent/AuthComponent";
import SplashLoadComponent from "./SplashLoadComponent/SplashLoadComponent";
import {Link} from "react-router-dom";
import { ROOT_DOMAIN_API } from "../Constant";

function Home() {
  const pageTab = "overview";
  
  const [amountValidatedWebsite, setAmountValidatedWebsite] = useState("/");
  const [prevAmountValidatedWebsite, setPrevAmountValidatedWebsite] = useState("/");
  const [averageCA, setAverageCA] = useState("/");
  const [prevAverageCA, setPrevAverageCA] = useState("/");
  const [amountActiveWebsite, setAmountActiveWebsite] = useState("/");
  const [prevAmountActiveWebsite, setPrevAmountActiveWebsite] = useState("/");
  const [amountReminders, setAmountReminders] = useState();
  const [amountOpportunities, setAmountOpportunities] = useState("/");
  const [amountDeletedAccount, setAmountDeletedAcount] = useState("/");
  const [splashLoad, setSplashLoad] = useState(true);
  const [amountDetagWebsite, setAmountDetagWebsite] = useState(0);

  useEffect(() => {
    localStorage.setItem('crm_redirection', false);
    setSplashLoad(true);
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getOpportunities");
        const obj = await resp.data;
        if(obj.results!=null){
          setAmountOpportunities(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getNbSiteDetag");
        const obj = await resp.data;
        if(obj.results!=null){
          setAmountDetagWebsite(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getDeletedAccount");
        const obj = await resp.data;
        if(obj.results!=null){
          setAmountDeletedAcount(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();
    
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getCountValidatedWebsites");
        const obj = await resp.data;
        if(obj.results!=null){
          setAmountValidatedWebsite(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getCountValidatedWebsites", {prev: true});
        const obj = await resp.data;
        if(obj.results!=null){
          setPrevAmountValidatedWebsite(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_averageCA");
        const obj = await resp.data;
        if(obj.results!=null){
          setAverageCA(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_averageCA", {prev: true});
        const obj = await resp.data;
        if(obj.results!=null){
          setPrevAverageCA(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_activeWebsite");
        const obj = await resp.data;
        if(obj.results!=null){
          setAmountActiveWebsite(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_activeWebsite", {prev: true});
        const obj = await resp.data;
        if(obj.results!=null){
          setPrevAmountActiveWebsite(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getCountReminders", {prev: true});
        const obj = await resp.data;
        if(obj.results!=null){
          setAmountReminders(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    setTimeout(() => {
      setSplashLoad(false);
    }, 3000)
  }, []);

  return (
    <div className="Home">
      <AuthComponent/>
      {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard"/> : ''}
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <LateralNavComponent pageTab={pageTab}/>
      <HeadLineComponent childClass="Headline" title="Welcome to your CSM space !" showDate={true}
                         setAmountValidatedWebsite={setAmountValidatedWebsite}
                         setAmountActiveWebsite={setAmountActiveWebsite}
                         setAverageCA={setAverageCA}
      />
      <CardProgressComponent childClass="Card1" action="validated_website" number={amountValidatedWebsite} subtitle="Validated Websites" icon="fa-solid fa-cart-arrow-down TPurple" progressColor="BPurple" prevValue={prevAmountValidatedWebsite}/>
      <CardProgressComponent childClass="Card2" action="active_website" number={amountActiveWebsite} subtitle="Activated websites" icon="fa-solid fa-chart-line TSky" progressColor="BSky" prevValue={prevAmountActiveWebsite}/>
      <CardProgressComponent childClass="Card3" action="average_ca" number={averageCA} currency="€" subtitle="Average CA" icon="fa-solid fa-wallet TPink" progressColor="BPink" prevValue={prevAverageCA}/>
      <CardProgressComponent childClass="Card4" action="publisher_contacted" number={1} subtitle="Publishers contacted" icon="fa-solid fa-paper-plane TYellow" progressColor="BYellow" prevValue={1}/>
      <Link to="/activation" className="DCard1 NoUnderline TBlack">
        <DualCardComponent childClass="DCard1" title="Alert detag" number={amountDetagWebsite}/>
      </Link>
      <Link to="/activation" className="DCard2 NoUnderline TBlack">
        <DualCardComponent childClass="DCard2" title="Opportunities" number={amountOpportunities}/>
      </Link>
      <Link to="/activation" className="DCard3 NoUnderline TBlack">
        <DualCardComponent childClass="DCard3" title="Reminders" number={amountReminders}/>
      </Link>
      <Link to="/activation" className="DCard4 NoUnderline TBlack">
        <DualCardComponent childClass="DCard4" title="Alert outsiders" number={amountDeletedAccount}/>
      </Link>
      <SiteStateComponent childClass="SiteState"/>
      <FreshdeskChartComponent childClass="ChartCard" />
    </div>
  );
}

export default Home;
