import './CommentRowComponent.css';
import { confirmAlert } from 'react-confirm-alert';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import ImageZoom from "react-image-zooom";
import {ROOT_DOMAIN_API} from "../../Constant";

function CommentRowComponent(props) {
  const [dynamicClass, setDynamicClass] = useState('');
  const [comPinned, setComPinned] = useState(0);
  const [labelTypeColor, setLabelTypeColor] = useState("LabelB0");

  useEffect(() => {
    if(props.comPinned===1){
      setComPinned(1);
    } else {
      setComPinned(0);
    }
  }, [props.comPinned])

  const handleDelete = function(){
    confirmAlert({
      title: 'Confirmation required',
      message: 'Are you sure to do this ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCard()
        },
        {
          label: 'No'
        }
      ]
    });
  }

  const handleCheckbox = function(e){
    let status = 0;
    if(comPinned){
      setComPinned(0);
    } else {
      setComPinned(1);
      status = 1;
    }
    (async () => {
      try {
        await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateCommentPinned", {commentId: props.comId, status: status});
      } catch (error) {
          console.error(error);
      }
    })();
  }
  

  const deleteCard = function(){
    (async () => {
      try {
        await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_deleteCommentById", {commentId: props.comId});
        setDynamicClass(" Hide");
        props.refresh();
      } catch (error) {
          console.error(error);
      }
    })();
  }

  useEffect(() => {
    switch(props.label){
      case "Technical problem":
        setLabelTypeColor("LabelB1")
        break;
      case "Paiement issue":
        setLabelTypeColor("LabelB2")
        break;
      case "Call":
        setLabelTypeColor("LabelB3")
        break;
      case "Profil modification":
        setLabelTypeColor("LabelB4")
        break;
      case "Optimisation":
        setLabelTypeColor("LabelB5")
        break;
      case "Blacklist":
        setLabelTypeColor("LabelB6")
        break;
      default:
        setLabelTypeColor("LabelB0")
        break;
    }
  }, [props.label]);

  return (
    <div className={"CommentRowComponent MT20 FlexRow"+dynamicClass}>
        <div className="FlexCol TextContainer">
          <div className="FlexRow FlexSpaceAround AlignCenter">
            <p className="SmallFont M0">{props.date}</p>
            <span className="FlexRow">
              <button onClick={() => handleDelete()} className="ActionButton">
                <i className="fa-regular fa-trash-can"></i>
              </button>
              <input className="PinCheckbox" type="checkbox" checked={(comPinned)} onChange={handleCheckbox}/>
            </span>
          </div>
            <p className="SmallFont M0">{props.text}</p>
            <p className="SmallFont MB0 TGrey">{props.author}</p>
            <div className={"LabelCard LabelCardFont " + labelTypeColor}>{props.label ? props.label : "No label" }</div>
            {props.img ? <ImageZoom src={`data:image/jpeg;base64,${props.img}`} alt="" zoom="200"/> : ''}
        </div>
    </div>
  );
}

export default CommentRowComponent;
