import ProspectionRow from "../ProspectionRowComponent/ProspectionRowComponent"
import './ProspectionComponent.css'

function ProspectionComponent(props) {
  return (
    <div className={"Prospection " + props.childClass}>
        <div className="CustomCard">
            <h3>Prospection info</h3>
            <div className="Overflow">
              {
              props.prospectionInfos.map(row => {
                  return <ProspectionRow key={row.site_pros_id} prospectionInfos={row}></ProspectionRow>
              })
              }
            </div>
        </div>
    </div>
  );
}
  
export default ProspectionComponent;
  