import './DualCardComponent.css';

function DualCardComponent(props) {
  return (
    <div className={"DualCard " + props.childClass}>
      <h2 className="MediumFont">{props.title}</h2>
      <p className="MediumFont">{props.number}</p>
    </div>
  );
}

export default DualCardComponent;
