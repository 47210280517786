import './App.css';
import HeaderComponent from './components/HeaderComponent/HeaderComponent';
import LoginComponent from './pages/LoginComponent/LoginComponent';
import ActivationComponent from './pages/ActivationComponent/ActivationComponent';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Home from './components/Home';
import CRMUserComponent from './pages/CRMUserComponent/CRMUserComponent.js';
import SupportComponent from './pages/SupportComponent/SupportComponent.js';
import axios from 'axios';
import ContactsComponent from './pages/ContactsComponent/ContactsComponent';
import AdminComponent from './pages/AdminComponent/AdminComponent';
import LogoutComponent from './pages/LogoutComponent/LogoutComponent';
import ExportComponent from './pages/ExportComponent/ExportComponent';
axios.defaults.withCredentials = true;

function App() {
  return (
    <div className="App">
      <HeaderComponent/>
        <BrowserRouter className="lateralNavbar" basename={"/"}>
          <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/crm_user/:id" element={<CRMUserComponent/>} />
              <Route path="/support" element={<SupportComponent/>} />
              <Route path="/activation" element={<ActivationComponent/>} />
              <Route path="/login" element={<LoginComponent/>} />
              <Route path="/contact" element={<ContactsComponent/>} />
              <Route path="/export" element={<ExportComponent/>} />
              <Route path="/logout" element={<LogoutComponent/>} />
              <Route path="/admin" element={<AdminComponent/>} />
              <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css"/>
    </div>
  );
}

export default App;
