import { useEffect } from 'react';
import { useState } from 'react';
import './EasyExportHistoryItemComponent.css';
import BarLoader from "react-spinners/BarLoader";
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../Constant";

function EasyExportHistoryItemComponent(props) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        if(props.status===2||props.status===0){
            setLoading(false);
        } else {
            let dateT = new Date(Date.parse(props.date));
            let DateN = new Date();
            if((DateN.getTime() - dateT.getTime()) > 3600000){
                setLoading(false);
                setError(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.status])

    const handleDownloadExport = function(){
        (async () => {
            try {
                const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getFileNameEasyExport", {export_id: props.id});
                const obj = await resp.data;
                if(obj.status&&obj.results){
                    // console.log(obj.results);
                    function downloadCSV(csvContent, fileName) {

                        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

                        const link = document.createElement('a');

                        const url = URL.createObjectURL(blob);
                        link.setAttribute('href', url);

                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(url);
                    }

                    downloadCSV(JSON.parse(obj.results).body, 'export_CRM.csv');
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }
    return (
        <div className="EasyExportHistoryItem">
            <div className="FlexRow AlignCenter JustifyBetween ItemHistory">
                <p>{props.date.slice(0, -3)}</p>
                <p>{props.name}</p>
                <BarLoader className="MR20" color="#dbdbdb" height="10px" width="30%" loading={loading}/>
                {props.status===0 ? <div className="Width30 FlexRow AlignCenter JustifyCenter">Queued</div> : ""}
                {!loading&&!error&&props.status!==0 ? <div className="Width30 FlexRow AlignCenter JustifyCenter"><button className="ActionButton" onClick={handleDownloadExport}><i className="fa-solid fa-download"></i></button></div> : ""}
                {!loading&&error&&props.status!==0 ? <div className="Width30 FlexRow AlignCenter JustifyCenter"><button disabled className="ActionButton ButtonDisabled BGrey"><i className="fa-solid fa-triangle-exclamation"></i></button></div> : ""}
            </div>
        </div>
    );
}

export default EasyExportHistoryItemComponent;
