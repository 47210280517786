import { useState, useEffect } from "react";
import axios from "axios";
import CSMUserDetailsComponent from "../../components/CSMUserDetailsComponent/CSMUserDetailsComponent";
import "./CSMCrewDetailsComponent.css";
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import 'react-day-picker/dist/style.css';
import { ROOT_DOMAIN_API } from "../../Constant";


function CSMCrewDetailsComponent(props) {
  const now = new Date();

  const [selectedDay1, setSelectedDay1] = useState();
  const [sD1Formatted, setSD1Formatted] = useState(format(new Date(now.getFullYear(), now.getMonth(), 1), 'yyyy-MM-dd'));
  const [dayPickerVisibility1, setDayPickerVisibility1] = useState(false);

  const [selectedDay2, setSelectedDay2] = useState();
  const [sD2Formatted, setSD2Formatted] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [dayPickerVisibility2, setDayPickerVisibility2] = useState(false);
  const [csmUserList, setCSMUserList] = useState([]);

  const toggleDayPickerVisibility1 = function(){
    if(dayPickerVisibility1){
      setDayPickerVisibility1(false);
    } else {
      setDayPickerVisibility1(true);
    }
}

const toggleDayPickerVisibility2 = function(){
    if(dayPickerVisibility2){
      setDayPickerVisibility2(false);
    } else {
      setDayPickerVisibility2(true);
    }
}

  useEffect(() => {
      if(!selectedDay1){
          return;
      }
      setSD1Formatted(format(selectedDay1, 'yyyy-MM-dd'))
  }, [selectedDay1]);

  useEffect(() => {
      if(!selectedDay2){
          return;
      }
      setSD2Formatted(format(selectedDay2, 'yyyy-MM-dd'))
  }, [selectedDay2]);

  useEffect(() => {
    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getAllCSMUser")
        const obj = await resp.data;
        if(obj.results){
          setCSMUserList(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }, []);
  return (
    <div className={"CSMCrewDetails " + props.childClass}>
      <h2>CSM Users details</h2>
      <div className="FlexRow">
          <div className="FlexCol">
              <button className="ActionButton BRed" onClick={() => toggleDayPickerVisibility1()}>
                  <i className="fa-solid fa-calendar"></i>&nbsp;From {sD1Formatted}</button>
              <DayPicker
                      format="M/D/YYYY"
                      className={"DayPickerObject " + (dayPickerVisibility1 ? '' : 'Hide')}
                      mode="single"
                      selected={selectedDay1}
                      onSelect={setSelectedDay1}
                  />
          </div>
          <div className="FlexCol ML20">
              <button className="ActionButton BRed" onClick={() => toggleDayPickerVisibility2()}>
                  <i className="fa-solid fa-calendar"></i>&nbsp;To {sD2Formatted}</button>
              <DayPicker
                      format="M/D/YYYY"
                      className={"DayPickerObject " + (dayPickerVisibility2 ? '' : 'Hide')}
                      mode="single"
                      selected={selectedDay2}
                      onSelect={setSelectedDay2}
                  />
          </div>
      </div>
      <div className="CSMCrewDetailsContainer">
        {csmUserList.map((row, key) => {
          return <CSMUserDetailsComponent key={key} adm_picture={row.adm_picture} adm_name={row.adm_name} adm_id={row.adm_id} d1={sD1Formatted} d2={sD2Formatted} />
        })}
      </div>
    </div>
  );
}

export default CSMCrewDetailsComponent;
