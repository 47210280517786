import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import './ContactsComponent.css'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import HeadLineExportComponent from '../../components/HeadLineExportComponent/HeadLineExportComponent';
import ContactsRowComponent from '../../components/ContactsRowComponent/ContactsRowComponent'
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import { ROOT_DOMAIN_API } from "../../Constant";

function ContactsComponent() {
  const pageTab = "contacts";
  const [offset, setOffset] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 20;

  useEffect(() => {
    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_usersList", {offset: offset})
        const obj = await resp.data;
        if(obj.results){
          setUsersList(obj.results.results);
          setPageCount(Math.ceil(obj.results.total / itemsPerPage));
          setTotalItems(obj.results.total);
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }, [offset]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalItems;
    setOffset(newOffset);
  };

  return (
    <div className={"Contacts"}>
      <AuthComponent/>
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <LateralNavComponent pageTab={pageTab}/>
      <HeadLineExportComponent childClass="Headline" title="Contact"/>
      <div className="ContactsCard">
        <table>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Phone</th>
              <th>Mail</th>
              <th>Name</th>
              <th>Inscription date</th>
              <th>Prio</th>
              <th>Parent</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {usersList.map((row, index) => {
              return <ContactsRowComponent
                childClass={"ContactsRowCard"}
                key={index} userId={row.user_id}
                userPhone={row.user_phone}
                userMail={row.user_mail}
                userName={row.user_lastname + ' ' + row.user_firstname}
                userDate={row.user_date}
                userPrio={row.prio}
                userSite={row.site_url}
                moderation={row.site_moderation}
                />
            })}
          </tbody>
        </table>
        <p className="TCenter">Page n°{(offset / 20) + 1}</p>
        <ReactPaginate
          className="Pagination"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}
  
export default ContactsComponent;
  