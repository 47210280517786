import "./HeadLineComponent.css";
import SearchFormUserSiteComponent from "../SearchFormUserSiteComponent/SearchFormUserSiteComponent"
import { MultiSelect } from "react-multi-select-component";
import { useState, useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import DateComponent from "../DateComponent/DateComponent";

function HeadLineComponent(props) {
  const username = localStorage.getItem('adm_name');
  const [selectedPrio, setSelectedPrio] = useState([{ label: "P1", value: "1" },{ label: "P2", value: "2" }]);
  const [selectedAssigned, setSelectedAssigned] = useState([]);
  const [selectedProspector, setSelectedProspector] = useState([]);
  const [selectedPlateform, setSelectedPlateform] = useState([{ label: "PT", value: "pt" },
  { label: "FR", value: "ww" },
  { label: "US", value: "us"},
  { label: "IT", value: "it"},
  { label: "DE", value: "de"},
  { label: "RU", value: "ru"},
  { label: "JP", value: "jp"},
  { label: "ES", value: "es"}]);
  const [optionsAssigned, setOptionsAssigned] = useState([]);
  const [optionsProspector, setOptionsProspector] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_everyone");
        const obj = await resp.data;
        if(obj.results!=null){
          let tmpList = [];
          obj.results.forEach((row, key) => {
            let tmpObj = {
              label: row.adm_name,
              value: row.adm_id
            }
            tmpList.push(tmpObj);
          })
          setOptionsAssigned(tmpList);
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }, [])

  useEffect(() => {
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getSalesList");
        const obj = await resp.data;
        if(obj.results!=null){
          let tmpList = [];
          obj.results.forEach((row, key) => {
            let tmpObj = {
              label: row.user_pros_name,
              value: row.user_pros_id
            }
            tmpList.push(tmpObj);
          })
          setOptionsProspector(tmpList);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [])

  useEffect(() => {
    if(props.changeFilters){
      props.changeFilters(selectedPrio, selectedPlateform, selectedAssigned, false, selectedProspector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrio, selectedPlateform, selectedAssigned, selectedProspector])

  const optionsPrio = [
    { label: "P1", value: "1" },
    { label: "P2", value: "2" },
    { label: "P3", value: "3"},
    { label: "P4", value: "4"}
  ];

  const optionsPlateform = [
    { label: "PT", value: "pt" },
    { label: "FR", value: "ww" },
    { label: "US", value: "us"},
    { label: "IT", value: "it"},
    { label: "DE", value: "de"},
    { label: "RU", value: "ru"},
    { label: "JP", value: "jp"},
    { label: "ES", value: "es"}
  ]

  return (
    <div className={"HeadLine FlexRow FlexBottom JustifyBetween " + props.childClass}>
        <div>
            <h1 className="M0">Hey {username},</h1>
            <h5 className="M0 MT5">{props.emoji ? <i className={props.emoji}></i> : ''}{props.title}</h5>
        </div>
        <div className="FlexRow">
          {props.filterActivation ? <div className="FlexCol">Priority <MultiSelect
                            className={"MultiSelect"}
                            options={optionsPrio}
                            value={selectedPrio}
                            onChange={setSelectedPrio}
                            labelledBy="Select"
                        /></div> : ''}

          {props.filterActivation ? <div className="FlexCol ML20">Plateform <MultiSelect
                            className={"MultiSelect"}
                            options={optionsPlateform}
                            value={selectedPlateform}
                            onChange={setSelectedPlateform}
                            labelledBy="Select"
                        /></div> : ''}
          {props.filterActivation ? <div className="FlexCol ML20">Assigned <MultiSelect
                            className={"MultiSelect"}
                            options={optionsAssigned}
                            value={selectedAssigned}
                            onChange={setSelectedAssigned}
                            labelledBy="Select"
                        /></div> : ''}
          {props.filterActivation ? <div className="FlexCol ML20">Prospector <MultiSelect
              className={"MultiSelect"}
              options={optionsProspector}
              value={selectedProspector}
              onChange={setSelectedProspector}
              labelledBy="Select"
          /></div> : ''}
          {props.showDate ? <DateComponent setAmountValidatedWebsite={props.setAmountValidatedWebsite}
                                           setAmountActiveWebsite={props.setAmountActiveWebsite}
                                           setAverageCA={props.setAverageCA}
          /> : ''}
        </div>
        <div className="FlexCol">
          <SearchFormUserSiteComponent></SearchFormUserSiteComponent>
        </div>
    </div>
  );
}

export default HeadLineComponent;
