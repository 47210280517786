import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import EasyExportHistoryItemComponent from '../EasyExportHistoryItemComponent/EasyExportHistoryItemComponent';
import './EasyExportHistoryComponent.css';
import { ROOT_DOMAIN_API } from "../../Constant";

function EasyExportHistoryComponent(props) {
    const [listExport, setListExport] = useState([]);

    useEffect(() => {
      setInterval(() => {
        refreshList();
      }, 5000)
      refreshList();
    }, [])


    useEffect(() => {
      refreshList();
    }, [props.hotReload])

    const refreshList = function(){
      (async () => {
        try {
            const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getEasyExportHistory");
            const obj = await resp.data;
            if(obj.results){
              setListExport(obj.results);
            }
        } catch (error) {
            console.error(error);
        }
      })();
    }

  return (
    <div className="EasyExportHistory">
      <h2>Your export history</h2>
        {listExport.map((row, key) => {
          return <EasyExportHistoryItemComponent key={key} status={row.status} id={row.id} date={row.date} name={row.name} />
        })
        }
        
        {listExport.length === 0 ? <p>No export history</p> : ''}
    </div>
  );
}

export default EasyExportHistoryComponent;