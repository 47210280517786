import './OpeSpeEditorComponent.css'
import axios from 'axios';
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import MDEditor from '@uiw/react-md-editor';
import {ROOT_DOMAIN_API} from "../../Constant";

function OpeSpeEditorComponent(props) {
  const [value, setValue] = useState("");
  useEffect(() => {
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getCrmOpeSpe");
        const obj = await resp.data;
        if(obj.status===true){
          setValue(obj.results.text)
        }
      } catch (error) {
          console.error(error);
      }
  })();
  }, []);

  const editorHandler = function(e){
    setValue(e);
    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateCrmOpeSpe", {value: e})
        await resp.data;
      } catch (error) {
          console.error(error);
      }
    })();
  }
  return (
    <div className={"OpeSpe " + props.childClass}>
      <h1 className="M0">Opé Spé Editor</h1>
        <MDEditor
        highlightEnable={false}
        className="MT20"
        value={value}
        onChange={e => editorHandler(e)}
      />
    </div>
  );
}
  
export default OpeSpeEditorComponent;
  