import LateralNavComponent from "../../components/LateralNavComponent/LateralNavComponent";
import './ActivationComponent.css';
import axios from "axios";
import { useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import LineCardComponent from "../../components/LineCardComponent/LineCardComponent";
import HeadLineComponent from "../../components/HeadLineComponent/HeadLineComponent";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import SplashLoadComponent from "../../components/SplashLoadComponent/SplashLoadComponent";
import { ROOT_DOMAIN_API } from "../../Constant";


function Activation() {
  const pageTab = "activation";

  const [firstContactList, setFirstContactList] = useState([]);
  const [opportunitiesList, setOpportunitiesList] = useState([]);
  const [remindersList, setRemindersList] = useState([]);
  const [inactiveList, setInactiveList] = useState([]);
  const [splashLoad, setSplashLoad] = useState(true);
  const [newActiveList, setNewActiveList] = useState([]);
  const [importantDetagList, setImportantDetagList] = useState([]);
  const [retagList, setRetagList] = useState([]);
  const [filterPlateformList, setFilterPlateformList] = useState([{label: "PT", value: "pt"},{label: "FR", value: "ww"},{label: "US", value: "us"},{label: "IT", value: "it"},{label: "DE", value: "de"},{label: "RU", value: "ru"},{label: "JP", value: "jp"},{label: "ES", value: "es"}]);
  const [filterAssignedList, setFilterAssignedList] = useState([]);
  const [filterProspectorList, setFilterProspectorList] = useState([]);
  const [filterPriorityList, setFilterPriorityList] = useState([{label: "P1", value: "1"}, {label: "P2", value: "2"}]);
  const [filterUS, setFilterUS] = useState(false);
  const [init, setInit] = useState(false);

  useEffect(() => {
    if(!init){
      return;
    }
    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getNewSiteActive", {fPlateform: filterPlateformList, fPriority: filterPriorityList, fAdmin: filterAssignedList, USDashboard: filterUS, fProspector: filterProspectorList});
        const obj = await resp.data;
        if(obj.results!=null){
          setNewActiveList(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getSiteDetag", {fPlateform: filterPlateformList, fPriority: filterPriorityList, fAdmin: filterAssignedList, USDashboard: filterUS, fProspector: filterProspectorList});
        const obj = await resp.data;
        if(obj.results!=null){
          setImportantDetagList(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getFirstContact", {fPlateform: filterPlateformList, fPriority: filterPriorityList, fAdmin: filterAssignedList, USDashboard: filterUS, fProspector: filterProspectorList});
        const obj = await resp.data;
        if(obj.results!=null){
          setFirstContactList(obj.results)
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_opportunitiesList", {fPlateform: filterPlateformList, fPriority: filterPriorityList, fAdmin: filterAssignedList, USDashboard: filterUS, fProspector: filterProspectorList});
        const obj = await resp.data;
        if(obj.results!=null){
          setOpportunitiesList(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getInactive", {fPlateform: filterPlateformList, fPriority: filterPriorityList, fAdmin: filterAssignedList, USDashboard: filterUS, fProspector: filterProspectorList});
        const obj = await resp.data;
        if(obj.results!=null){
          setInactiveList(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getReminder");
        const obj = await resp.data;
        if(obj.results!=null){
          setRemindersList(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getSiteRetag", {fPlateform: filterPlateformList, fPriority: filterPriorityList, fAdmin: filterAssignedList, USDashboard: filterUS, fProspector: filterProspectorList});
        const obj = await resp.data;
        if(obj.results!=null){
          setRetagList(obj.results)
        }
      } catch (error) {
          console.error(error);
      }
    })();

  }, [filterPriorityList, filterPlateformList, filterAssignedList, filterUS, init, filterProspectorList]);

  useEffect(() => {
    setSplashLoad(true);
    setTimeout(() => {
      setSplashLoad(false);
    }, 3000)
  }, [])

  const refreshData = function(){
    //setHotReload(new Date().valueOf());
  }

  const changeFilters = function(fPrio, fPlateform, fAssigned, fUS, fProspector){
    setInit(true);
    setFilterPriorityList(fPrio);
    setFilterPlateformList(fPlateform);
    setFilterAssignedList(fAssigned)
    setFilterUS(fUS);
    setFilterProspectorList(fProspector);
  }

  return (
    <div className="Activation">
      <AuthComponent/>
      {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard"/> : ''}
      <ToastContainer
          theme="colored"
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
      <LateralNavComponent pageTab={pageTab}/>
      <HeadLineComponent changeFilters={changeFilters} filterActivation={true} altUSAvaible="true" emoji="fa-regular fa-flag TBlack" childClass="Headline" title=" - Welcome to your activation space !"/>
      <LineCardComponent childClass="LineCard1" refresh={refreshData} headLineColor="BSoftPink" data={firstContactList} action="first_contact" title="First Contact"/>
      {/* <LineCardComponent childClass="LineCard2" refresh={refreshData} headLineColor="BSoftPink" data={welcomeCallList} action="welcome_call" title="Welcome call"/> */}
      <LineCardComponent childClass="LineCard3" refresh={refreshData} headLineColor="BSoftPurple" data={inactiveList} action="inactive" title="Inactives"/>
      <LineCardComponent childClass="LineCard4" refresh={refreshData} headLineColor="BGreen" data={newActiveList} action="new_active" title="New active"/>
      <LineCardComponent childClass="LineCard5" refresh={refreshData} headLineColor="BRed" data={opportunitiesList} action="opportunities" title="Opportunities"/>
      <LineCardComponent childClass="LineCard6" refresh={refreshData} headLineColor="BOrange" data={remindersList} action="reminders" title="Reminders"/>
      <LineCardComponent childClass="LineCard7" refresh={refreshData} headLineColor="BSoftPurple" data={importantDetagList} action="important_detag" title="Important detag"/>
      <LineCardComponent childClass="LineCard8" refresh={refreshData} headLineColor="BSoftPurple" data={retagList} action="retag" title="Retag (1 month without perf)"/>
    </div>
  );
}

export default Activation;
