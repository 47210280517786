import { useNavigate} from "react-router-dom";
import { useEffect} from 'react';
import axios from 'axios';
import { ROOT_DOMAIN_API } from "../../Constant";
axios.defaults.withCredentials = true;

function AuthComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_ping");
        const obj = await resp.data;
        if(obj.status!==true){
          navigate('/login', { replace: true });
        }
        
        localStorage.setItem('adm_name', obj.results.username);
      } catch (error) {
          console.error(error);
          navigate('/login', { replace: true });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="Auth"></div>
  );
}

export default AuthComponent;
