import './ExportComponent.css';
import { useState, useEffect } from "react";
import SplashLoadComponent from "../../components/SplashLoadComponent/SplashLoadComponent";
import EasyExportComponent from "../../components/EasyExportComponent/EasyExportComponent";
import EasyExportHistoryComponent from '../../components/EasyExportHistoryComponent/EasyExportHistoryComponent';
import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import HeadLineExportComponent from '../../components/HeadLineExportComponent/HeadLineExportComponent';
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import { ToastContainer } from 'react-toastify';

function Export() {
    const [splashLoad, setSplashLoad] = useState(true);
    const [hotReload, setHotReload] = useState(Date.now());
    const pageTab = "export";

    const refreshExportHistory = function(){
      setHotReload(Date.now());
    }

    useEffect(() => {
        setSplashLoad(true);
        setTimeout(() => {
        setSplashLoad(false);
        }, 3000)
    }, []);

  return (
    <div className="Export">
        <AuthComponent/>
        {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard"/> : ''}
        <ToastContainer
            theme="colored"
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
        <LateralNavComponent pageTab={pageTab}/>
        <HeadLineExportComponent childClass={"HeadLineExport"} title="Export tool"/>
        <EasyExportComponent refreshExportHistory={refreshExportHistory}/>
        <EasyExportHistoryComponent hotReload={hotReload}/>
    </div>
  );
}

export default Export;
