import './WebsitesComponent.css';

function WebsitesComponent(props) {
  return (
    <div className={"Websites " + props.childClass}>
        <h3>Websites info</h3>
        <div className="FlexRow FlexSpaceAround">
            <p className="MT0 MB0 HalfWidth SmallFont"><i className="fa-solid fa-bag-shopping TGrey MR5 MR10"></i>Number of websites</p>
            <p className="MT0 MB0 HalfWidth SmallFont">{props.listSiteInfos.length}</p>
        </div>
        <div className="FlexRow FlexSpaceAround">
            <p className="MT0 MB0 HalfWidth SmallFont"><i className="fa-solid fa-piggy-bank TGrey MR5 MR10"></i>Payment type</p>
            <p className="MT0 MB0 HalfWidth SmallFont">{props.publisherInfos.user_bank_name ? "Bank" : (props.publisherInfos.user_paypal_mail ? "Paypal" : "None")}</p>
        </div>
        <div className="FlexRow FlexSpaceAround">
            <p className="MT0 MB0 HalfWidth SmallFont"><i className="fa-solid fa-euro-sign TGrey MR5 MR10"></i>Amount since last invoice</p>
            <p className="MT0 MB0 HalfWidth SmallFont">{props.publisherInfos.total_amount_since_last_invoice}</p>
        </div>
        <div className="FlexRow FlexSpaceAround">
            <p className="MT0 MB0 HalfWidth SmallFont"><i className="fa-solid fa-bolt TGrey MR5 MR10"></i>First perf date</p>
            <p className="MT0 MB0 HalfWidth SmallFont">{props.publisherInfos.first_perf ? (props.publisherInfos.first_perf.cohorte_first_perf ?? "Never") : "Never" }</p>
        </div>
    </div>
  );
}
  
export default WebsitesComponent;