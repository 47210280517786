import './AlertRowComponent.css'
import 'react-toastify/dist/ReactToastify.css';

function AlertRowComponent(props) {
  return (
    <tr className="AlertRow">
        <td>
          <a className="ActionLink" target="blank" href={props.true_url}>{props.url}</a>
        </td>
        <td>
          <a className="ActionLink" target="blank" href={"https://bo.themoneytizer.com/options/" + props.siteId}>{props.siteId}</a>
        </td>
        <td>{props.date}</td>
        <td>{props.name+" ("+props.value+")"}</td>
        <td>{props.adm_name}</td>
    </tr>
  );
}
  
export default AlertRowComponent;
  