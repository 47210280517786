import { useEffect, useState } from 'react';
import './AlertComponent.css'
import axios from 'axios';
import AlertRowComponent from '../AlertRowComponent/AlertRowComponent';
import {ROOT_DOMAIN_API} from "../../Constant";

function AlertComponent(props) {
    const [alertsList, setAlertsList] = useState([]);

    useEffect(() => {
      setTimeout(()=>{
        (async () => {
          if(!props.userID){
            return;
          }
          try {
            const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getAlerts", {userId: props.userID})
            const obj = await resp.data;
            if(obj.results){
              setAlertsList(obj.results);
            }
          } catch (error) {
              console.error(error);
          }
        })();
      }, 1500)
    }, [props.userID])
    return (
        <div className={"Alert " + props.childClass}>
              <div className="FlexRow FlexSpaceAround AlignCenter">
                <h3>Alerts history</h3>
              </div>
              <div className="ContainerAlertsListTable">
                <table className="FullWidth AlertList">
                  <thead>
                    <tr>
                      <th>URL & SSP</th>
                      <th>ID</th>
                      <th>Dates</th>
                      <th>Types</th>
                      <th>Author</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alertsList.map(row => {
                      return <AlertRowComponent key={row.id} id={row.id} siteId={row.site_id} true_url={row.site_true_url} url={row.site_url} date={row.date} subtype={row.type} adm_name={row.adm_name} name={row.name} value={row.value}/>
                    })}
                  </tbody>
                </table>
              </div>
        </div>
    );
}
  
export default AlertComponent;
  