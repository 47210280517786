import { useNavigate} from "react-router-dom";
import axios from 'axios';
import { useEffect} from 'react';
import { ROOT_DOMAIN_API } from "../../Constant";
axios.defaults.withCredentials = true;

function AuthAdminComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_ping");
        const obj = await resp.data;
        if(obj.status!==true){
          navigate('/login', { replace: true });
        }
        
        if(!["85","77","95", "6", "7", "10", "103", "74", "110"].includes(obj.adm_id)){
          navigate('/crm', { replace: true });
        }
      } catch (error) {
          console.error(error);
          navigate('/login', { replace: true });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="AdminAuth"></div>
  );
}

export default AuthAdminComponent;
