import LateralNavComponent from "../../components/LateralNavComponent/LateralNavComponent";
import CardProgressComponent from "../../components/CardProgressComponent/CardProgressComponent";
import './SupportComponent.css';
import axios from "axios";
import { useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import TicketComponent from "../../components/TicketComponent/TicketComponent";
import HeadLineComponent from "../../components/HeadLineComponent/HeadLineComponent";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import SplashLoadComponent from "../../components/SplashLoadComponent/SplashLoadComponent";
import FreshdeskDateSatisfactionAndTagComponent from "../../components/FreshdeskDateSatisfactionAndTagComponent/FreshdeskDateSatisfactionAndTagComponent";
import { ROOT_DOMAIN_API } from "../../Constant";

function Support() {
  const [amountCreatedTickets, setAmountCreatedTickets] = useState(0);
  const [amountUnresolvedTickets, setAmountUnresolvedTickets] = useState(0);
  const [splashLoad, setSplashLoad] = useState(true);
  const pageTab = "support";

  useEffect(() => {
    setSplashLoad(true);
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getTicketsCreated");
        const obj = await resp.data;
        if(obj.results!=null){
          setAmountCreatedTickets(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getTicketsUnresolved");
        const obj = await resp.data;
        if(obj.results!=null){
          setAmountUnresolvedTickets(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();

    setTimeout(() => {
      setSplashLoad(false);
    }, 3000)
  }, []);

  return (
    <div className="Support">
      <AuthComponent/>
      {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard"/> : ''}
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <LateralNavComponent pageTab={pageTab}/>
      <HeadLineComponent childClass="Headline" title="Welcome to your support space !"/>
      <CardProgressComponent childClass="Card1" action="validated_website" number={amountCreatedTickets} subtitle="Tickets created" icon="fa-solid fa-ticket TPurple" progressColor="BPurple" hideProgress="true"/>
      <CardProgressComponent childClass="Card2" action="active_website" number={amountUnresolvedTickets} subtitle="Unresolved tickets" icon="fa-solid fa-thumbtack TSky" progressColor="BSky" hideProgress="true"/>
      <TicketComponent childClass="TicketList"/>
      <FreshdeskDateSatisfactionAndTagComponent childClass="FreshdeskDateSatisfactionAndTagCard" />
    </div>
  );
}

export default Support;
