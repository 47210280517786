import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import PublisherInfosComponent from '../../components/PublisherInfosComponent/PublisherInfosComponent';
import './CRMUserComponent.css'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TimelineComponent from '../../components/TimelineComponent/TimelineComponent';
import HeadLineComponent from '../../components/HeadLineComponent/HeadLineComponent';
import ProspectionComponent from '../../components/ProspectionComponent/ProspectionComponent';
import CommentComponent from '../../components/CommentComponent/CommentComponent';
import ContactComponent from '../../components/ContactComponent/ContactComponent';
import AlertComponent from '../../components/AlertComponent/AlertComponent';
import WebsitesComponent from '../../components/WebsitesComponent/WebsitesComponent';
import UsersWebsiteComponent from '../../components/UsersWebsiteComponent/UsersWebsiteComponent';
import BillsComponent from '../../components/BillsComponent/BillsComponent';
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import SplashLoadComponent from '../../components/SplashLoadComponent/SplashLoadComponent';
import {ROOT_DOMAIN_API} from "../../Constant";

function CRMUserComponent() {
  const pageTab = "overview";
  let { id } = useParams();
  const [userID, setUserID] = useState();
  const [publisherInfos, setPublisherInfos] = useState({});
  const [listSiteInfos, setListSiteInfos] = useState([]);
  const [listBills, setListBills] = useState([]);
  const [prospectionInfos, setProspectionInfos] = useState([]);
  const [splashLoad, setSplashLoad] = useState(true);

  useEffect(() => {
    setUserID(id);
    setSplashLoad(true);
    (async () => {
      if(!id){
        return;
      }
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getUserFromID", {userId: id})
        const obj = await resp.data;
        setPublisherInfos(obj.results.user);
        setListSiteInfos(obj.results.sites);
        setProspectionInfos(obj.results.prospections);
        setListBills(obj.results.bills);
      } catch (error) {
          console.error(error);
      }
    })();

    setTimeout(() => {
      setSplashLoad(false);
    }, 3000)
  }, [id]);

  return (
    <div className="CRMUser">
      {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard"/> : ''}
      <AuthComponent/>
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <LateralNavComponent pageTab={pageTab}/>
      <HeadLineComponent childClass="Headline" title="Welcome to your CSM space !"/>
      <PublisherInfosComponent childClass="PublisherInfos" userId={userID}/>
      <ProspectionComponent childClass="ProspectionCard" prospectionInfos={prospectionInfos}/>
      <CommentComponent childClass="CommentCard" userId={userID}/>
      <ContactComponent childClass="ContactCard" userId={userID} publisherInfos={publisherInfos}/>
      <TimelineComponent childClass="TimelineCard" userID={userID}/>
      <AlertComponent childClass="AlertCard" userID={userID}/>
      <WebsitesComponent childClass="WebsitesCard" publisherInfos={publisherInfos} listSiteInfos={listSiteInfos}/>
      <UsersWebsiteComponent childClass="UserWebsiteCard" listSiteInfos={listSiteInfos}/>
      <BillsComponent childClass="BillsCard" listBills={listBills} />
    </div>
  );
}
  
export default CRMUserComponent;
  