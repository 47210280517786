import './CommentComponent.css'
import CommentRowComponent from '../CommentRowComponent/CommentRowComponent';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FileUploader } from "react-drag-drop-files";
import { ROOT_DOMAIN_API } from "../../Constant";

function CommentComponent(props) {
  const [toggleCommentField, setToggleCommentField] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState("");
  const [commentCategory, setCommentCategory] = useState(1);
  const [commentsLabelList, setCommentsLabelList] = useState([]);
  
  const fileTypes = ["JPEG", "JPG", "PNG", "GIF"];
  const [commentFile, setCommentFile] = useState(null);
  const handleFileChange = (tmp_file) => {
    if (!tmp_file || tmp_file.length === 0) return; // Aucun fichier n'a été sélectionné
  
    const file = tmp_file[0];
  
    // Création d'un FileReader pour lire le contenu du fichier
    const reader = new FileReader();
  
    reader.onload = function(event) {
      // Une fois que le fichier est lu, event.target.result contient un data URL (base64)
      const base64String = event.target.result.split(',')[1]; // Split pour obtenir uniquement la partie base64
      setCommentFile(base64String);
    };
  
    reader.onerror = function(error) {
      console.error("Erreur lors de la lecture du fichier:", error);
    };

    reader.readAsDataURL(file);
  };

  const handleCommentFieldVisibility = function(){
    if(toggleCommentField){
      setToggleCommentField(false);
    } else {
      setToggleCommentField(true);
    }
  }

  const handleCategoryChange = function (e){
    setCommentCategory(e.target.value)
    console.log(e.target.value)
  }

  const handleCommentChange = function (e){
    setComment(e.target.value);
  }

  const handleCommentSave = function (){
    if(comment===""){
      return;
    }
    if(!props.userId){
      return;
    }
    (async () => {
      try {
        await axios.post(
            ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_addComments",
          {
            userId: props.userId,
            comment: comment,
            commentCategory: commentCategory,
            commentFile: commentFile
          }
        );
        setComment("");
        setToggleCommentField(false);
        toast.success('Comment has been added.', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        const resp2 = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getCommentsByUserId", {userId: props.userId});
        const obj2 = await resp2.data;
        if(obj2.results!=null){
          setCommentList(obj2.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }

  useEffect(() => {
    if(!props.userId){
      return;
    }
    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getCommentsByUserId", {userId: props.userId});
        const obj = await resp.data;
        if(obj.results!=null){
          setCommentList(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }, [props.userId])

  useEffect(() => {
    if(!props.userId){
      return;
    }
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getCommentsNewCategory");
        const obj = await resp.data;
        if(obj.results!=null){
          setCommentsLabelList(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }, [props.userId])

  return (
    <div className={"Prospection " + props.childClass}>
        <div className="FlexRow FlexSpaceAround AlignCenter">
            <h3>CSM Comment</h3>
            <button onClick={handleCommentFieldVisibility} disabled={toggleCommentField} className={"ActionButton ButtonMiddleSize "+ (toggleCommentField ? "HalfOpacity" : "")}><i className="fa-solid fa-plus"></i> Add new</button>
        </div>
        <div className={"FlexCol CSMCommentContainer "+ (!toggleCommentField ? "Hide" : "")}>
          <div className="FlexRow JustifyBetween">
          <textarea className="TextAreaComment" onChange={e => handleCommentChange(e)} value={comment}></textarea>
          <div className="FlexCol OptionsCol">
            <select className="SelectComment" onChange={e => handleCategoryChange(e)} value={commentCategory}>
              {commentsLabelList.map((row, key) => {
                return <option key={key} value={row.id}>{row.libelle}</option>
              })}
            </select>
            <div className='MT10'>
              <FileUploader
                multiple={true}
                handleChange={handleFileChange}
                name="commentFile"
                types={fileTypes}
              />
            </div>
            
            <button onClick={handleCommentFieldVisibility} className="ActionButton ButtonMiddleSize FullWidth MT10 BOrange"><i className="fa-solid fa-times"></i> Cancel</button>
            <button onClick={handleCommentSave} className="ActionButton ButtonMiddleSize FullWidth MT10"><i className="fa-solid fa-check"></i> Save</button>
          
            </div>
          </div>
          
          
          <div className="FlexRow JustifyEnd">
            
            </div>
        </div>
        <div className="CommentRowContainerList">
          {commentList.map((row, index) => {
              return <CommentRowComponent key={index} label={row.category_libelle} comId={row.com_id} author={row.adm_name} comPinned={row.com_pinned} text={row.com_comment} date={row.com_date} img={row.com_img}/>
          })}
        </div>
    </div>
  );
}
  
export default CommentComponent;
  