import './FreshdeskChartComponent.css'
import { useEffect } from 'react';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useState } from 'react';
import { ROOT_DOMAIN_API } from '../../Constant';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement);

function FreshdeskChartComponent(props) {
  const [graphData, setGraphData] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_allTicketsStatus");
        const obj = await resp.data;
        var dateList = [];
        var createdList = [];
        var resolvedList = [];
        var unresolvedList = [];

        console.log(obj.results);

        if(obj.results!=null){
          obj.results.forEach((row, key) => {
            dateList.push(row.date);
            createdList.push(row.nb_tickets_created);
            resolvedList.push(row.nb_tickets_resolved);
            unresolvedList.push(row.nb_tickets_unresolved);
          })

          const labels = dateList;
          var data = {
              labels,
              datasets: [
                  {
                      label: 'Tickets open',
                      lineTension: 0.8,
                      data: createdList,
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 1)',
                      yAxisID: 'y',
                  },
                  {
                      label: 'Tickets resolved',
                      lineTension: 0.7,
                      data: resolvedList,
                      borderColor: 'rgb(53, 162, 235)',
                      backgroundColor: 'rgba(53, 162, 235, 1)',
                      yAxisID: 'y',
                  },
                  {
                      label: 'Unresolved tickets',
                      lineTension: 0.7,
                      data: unresolvedList,
                      borderColor: 'rgba(241, 196, 15,1.0)',
                      backgroundColor: 'rgba(241, 196, 15,1.0)',
                      yAxisID: 'y',
                  },
              ],
            };

          setGraphData(data);
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }, []);

    

  return (
    <div className={"FreshdeskChart " + props.childClass}>
        <h2 className="M0">Tickets statistics</h2>
        {graphData !== null ? <Line data={graphData} /> : ''}
    </div>
  );
}
  
export default FreshdeskChartComponent;
  