import { useState, useEffect } from "react";
import axios from "axios";
import "./CSMUserDetailsComponent.css";
import {ROOT_DOMAIN_API} from "../../Constant";


function CSMUserDetailsComponent(props) {
  const [listDetails, setListDetails] = useState([]);
  useEffect(() => {
    (async () => {
        if(!props.adm_id){
            return;
        }
        try {
            const resp = await axios(ROOT_DOMAIN_API+'/manage/v1/crm_user/crm_getCrmLogsByAdmin?admin_id='+props.adm_id+"&d1="+props.d1+"&d2="+props.d2);
            const obj = await resp.data;
            if(obj.results&&obj.results){
              setListDetails(obj.results)
            }
        } catch (error) {
            console.error(error);
        }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.d1, props.d2]);
  return (
    <div className="CSMUserDetails">
        {props.adm_picture ? <img alt="Avatar admin" class="Avatar" src={"https://www.themoneytizer.com/modules/mod_new_home/img/contacts/"+props.adm_picture}/> : <p class="AvatarAlt M0">/</p>}
        <h4>{props.adm_id} | {props.adm_name}</h4>
        <div>
          <p>Statistics</p>
          {listDetails.map((row, key) => {
           return  <p className="M0"><i>{row.name} :</i> {row.total}</p>
          })}
        </div>
    </div>
  );
}

export default CSMUserDetailsComponent;
