import './SearchFormUserSiteComponent.css';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ROOT_DOMAIN_API } from "../../Constant";

function Home() {
    const [selectedResult, setSelectedResult] = useState("");
    const navigate = useNavigate();

    const handleSelection = value => {
        setSelectedResult(value);
        navigate('/crm_user/'+value.user_id, { replace: true });
    }

    const loadOptions = (inputValue) => {
      if(!inputValue){
        return null;
      }
      return axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_searchUserSite", {query: inputValue})
      .then(res => res.data)
      .then(data => data.results)
    };

  return (
    <div className="SearchFromUserSite ML20">
        <AsyncSelect
        className="SelectField"
        cacheOptions
        defaultOptions
        value={selectedResult}
        getOptionLabel={e => {return "["+e.user_id+"] "+e.user_firstname+" "+e.user_lastname+" - ["+e.site_id+"] "+ e.site_url} }
        getOptionValue={e => e.user_id}
        loadOptions={loadOptions}
        onChange={handleSelection}
      />
    </div>
  );
}

export default Home;
