import { useState } from 'react';
import LabelCardComponent from '../LabelCardComponent/LabelCardComponent';
import './SitePublisherRowComponent.css'
import axios from 'axios';
import { useEffect } from 'react';
import {ROOT_DOMAIN_API} from "../../Constant";

function SitePublisherRowComponent(props) {
  const [siteFraud, setSiteFraud] = useState(0);

  useEffect(() => {
    if(!props.siteFraud){
      return;
    }

    setSiteFraud(props.siteFraud);
  }, [props.siteFraud])

  const updateSiteFraud = function(){
    (async () => {
      try {
        if(!props.id){
          return;
        }
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_updateSiteFraud", {site_id: props.id, fraud: siteFraud})
        await resp.data;
      } catch (error) {
          console.error(error);
      }
    })();
  }
  
  return (
    <tr className="SitePublisherRow P5">
        <td className="VAlignTop">
          <p className="SmallFont M0">{props.url}</p>
        </td>
        <td className="VAlignTop">
          <p className="SmallFont M0">
            <a className="ActionLink SmallFont" href={"https://bo.themoneytizer.com/options/" + props.id}>{props.id}</a>
          </p>
        </td>
        <td className="VAlignTop">
          <div className="FlexRow">
            <div className="FlexRow SmallFont">
              {props.site_moderation === '1' ? <LabelCardComponent color="BPurple" icon="fa-solid fa-gavel" text={"Status Pending"}/> : ''}
              {props.site_moderation === '2' ? <LabelCardComponent color="BGreen" icon="fa-solid fa-gavel" text={"Status Accepted"}/> : ''}
              {props.site_moderation === '0' ? <LabelCardComponent color="BOrange" icon="fa-solid fa-gavel" text={"Status Refused"}/> : ''}
              {props.site_moderation === '3' ? <LabelCardComponent color="BPurple" icon="fa-solid fa-gavel" text={"Status WC"}/> : ''}
              {props.site_moderation === '4' ? <LabelCardComponent color="BOrange" icon="fa-solid fa-gavel" text={"Status Deleted"}/> : ''}
              {props.site_moderation === null ? <LabelCardComponent color="BBlack" icon="fa-solid fa-gavel" text={"Status OVH"}/> : ''}
              {props.child === "1" ? <LabelCardComponent color="BYellow" icon="fa-solid fa-baby" text="Child" /> : ''}
              {props.child === "0" ? <LabelCardComponent color="BRed" icon="fa-solid fa-person-pregnant" text="Parent" /> : ''}
              <LabelCardComponent color="BBlue" icon="fa-solid fa-tag" text={"CMP " + props.cmp }/>
              <LabelCardComponent color="BSky" icon="fa-solid fa-tag" text={"AdsTXT " + props.adsTxt }/>
              {props.wordpress !== null ? <LabelCardComponent color="BOrange" icon="fa-brands fa-wordpress" text={"WP " + (props.wordpress)}/> : '' }
              {props.date_validation ? <LabelCardComponent color="BPurple" icon="fa-solid fa-calendar-days" text={"Validation date " + (props.date_validation)}/> : ''}
              {props.site_actif === "1" ? <LabelCardComponent color="BPurplePink" icon="fa-solid fa-dollar-sign" text="Active"/> : <LabelCardComponent color="BBlack" icon="fa-solid fa-dollar-sign" text="Inactive"/>}
              { props.piracy ? <LabelCardComponent childClass="MR10 MB10" color="BRed" text={props.piracy}/> : '' }
              { props.pop_sourced ? <LabelCardComponent childClass="MR10 MB10" color="BRed" text={props.pop_sourced}/> : '' }
              { props.embedded ? <LabelCardComponent childClass="MR10 MB10" color="BRed" text={props.embedded}/> : '' }
              { props.MFA ? <LabelCardComponent childClass="MR10 MB10" color="BOrange" text={props.MFA}/> : '' }
              { props.pop_creator ? <LabelCardComponent childClass="MR10 MB10" color="BOrange" text={props.pop_creator}/> : '' }
              { props.new_reg_flag ? <LabelCardComponent childClass="MR10 MB10" color="BOrange" text={props.new_reg_flag}/> : '' }
            </div>
          </div>
        </td>
        <td className="VAlignTop">
            <input className="ActionInput ML5 Width5" type="number" value={siteFraud} onChange={(e) => {setSiteFraud(e.target.value)}}/>
            <button onClick={updateSiteFraud} className='ActionButton BRed ML5 MR20'>Update site fraud</button>
            <a className="ActionButton BBlue" href={"https://bo.themoneytizer.com/statistics/" + props.id}><i className="fa-solid fa-chart-column"></i></a>
        </td>
    </tr>
  );
}
  
export default SitePublisherRowComponent;