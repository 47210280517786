import './ModalComponent.css';

function ModalComponent(props) {
  return (
    <div className={"Modal "+ (props.modalVisibility ? '' : 'Hide')}>
        <div className="FlexRow FlexSpaceAround AlignCenter MT15 ML15">
            <p>Preview : {props.title}</p>
            <div className="CloseContainer MR15">
                <button onClick={props.toggleModalVisibility}><i className="fa-solid fa-xmark"></i></button>
            </div>
        </div>
        <div className="HTMLContainer">
            <iframe title='iframe reader' srcDoc={props.unsafeHTML}></iframe>
        </div>
    </div>
  );
}
  
export default ModalComponent;