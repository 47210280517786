import './ProspectionRowComponent.css'
import LabelCardComponent from "../LabelCardComponent/LabelCardComponent"

function ProspectionRowComponent(props) {
  return (
    <div className="ProspectionRow FlexCol FlexSpaceAround MB20">
        <div className="FlexRow FlexSpaceAround">
            <p className="MT0 MB0 HalfWidth SmallFont"><i className="fa-solid fa-link TGrey MR5 MR10"></i>URL</p>
            <p className="MT0 MB0 HalfWidth SmallFont">
              <a href={props.prospectionInfos.site_pros_url} className="ActionLink">{props.prospectionInfos.site_pros_url}</a>
            </p>
        </div>
        <div className="FlexRow FlexSpaceAround">
            <p className="MT0 MB0 HalfWidth SmallFont"><i className="fa-regular fa-calendar TGrey MR5 MR10"></i>Subscribtion date</p>
            <p className="MT0 MB0 HalfWidth SmallFont">{props.prospectionInfos.site_pros_date_inscrit}</p>
            </div>
            <div className="FlexRow FlexSpaceAround">
            <p className="MT0 MB0 HalfWidth SmallFont"><i className="fa-solid fa-user TGrey MR5 MR10"></i>Prospector</p>
            <p className="MT0 MB0 HalfWidth SmallFont">{props.prospectionInfos.user_pros_name}</p>
        </div>
        <div className={"FlexCol FlexSpaceAround "+(props.prospectionInfos.site_pros_abstract ? "" : "Hide")}>
            <p className="MT0 MB0 FullWidth SmallFont"><i className="fa-solid fa-align-left TGrey MR5 MR10"></i>Abstract</p>
            <textarea className="AbstractField MT5 SmallFont" disabled value={props.prospectionInfos.site_pros_abstract ?? "No abstract currently avaible for this website."}></textarea>
        </div>
        <div className="FlexRow TagsContainer">
            {props.prospectionInfos.tags.map((row, key) => {
              return <LabelCardComponent key={key} icon="fa-solid fa-tag" color="BRed" text={row.ptl_label}></LabelCardComponent>
            })}
        </div>
    </div>
  );
}
  
export default ProspectionRowComponent;