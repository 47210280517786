import './UsersWebsiteComponent.css';
import SitePublisherRowComponent from '../SitePublisherRowComponent/SitePublisherRowComponent'

function UsersWebsiteComponent(props) {
    return (
        <div className={"UsersWebsites " + props.childClass} id="UsersWebsites">
            <h3>User's websites</h3>
            <div className="UserWebsitesTable">
                <table>
                    <thead>
                        <tr>
                        <th>URL & SSP</th>
                        <th>ID</th>
                        <th>Info & Stats</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.listSiteInfos.map(row => {
                            return <SitePublisherRowComponent
                                key={row.site_id}
                                url={row.site_url}
                                id={row.site_id}
                                siteFraud={row.site_fraud}
                                child={row.site_child}
                                cmp={row.site_cmp}
                                adsTxt={row.site_ads_txt}
                                wordpress={row.wp_version}
                                site_moderation={row.site_moderation}
                                date_validation={row.site_date_validation}
                                site_actif={row.site_actif}
                                piracy={row.piracy}
                                pop_sourced={row.pop_sourced}
                                embedded={row.embedded}
                                MFA={row.MFA}
                                pop_creator={row.pop_creator}
                                new_reg_flag={row.new_reg_flag}
                                />
                                })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
  
export default UsersWebsiteComponent;