import LateralNavComponent from "../../components/LateralNavComponent/LateralNavComponent";
import './AdminComponent.css';
import { useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import OpeSpeEditorComponent from "../../components/OpeSpeEditorComponent/OpeSpeEditorComponent";
import AuthAdminComponent from "../../components/AuthAdminComponent/AuthAdminComponent";
import CSMCrewDetailsComponent from "../../components/CSMCrewDetailsComponent/CSMCrewDetailsComponent";
import SplashLoadComponent from "../../components/SplashLoadComponent/SplashLoadComponent";


function AdminComponent() {
  const pageTab = "admin";
  const [splashLoad, setSplashLoad] = useState(true);
  
  setTimeout(() => {
    setSplashLoad(false);
  }, 5000)

  useEffect(() => {
    setSplashLoad(true);
  }, []);

  return (
    <div className="Admin">
      <AuthAdminComponent/>
      {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard"/> : ''}
      <ToastContainer
          theme="colored"
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
          <LateralNavComponent pageTab={pageTab}/>
          <OpeSpeEditorComponent childClass="EditorCard"/>
          <CSMCrewDetailsComponent childClass="CSMUserDetailsCard"/>
      </div>
  );
}

export default AdminComponent;
