import './SiteStateComponent.css';
import SiteStateRowComponent from '../SiteStateRowComponent/SiteStateRowComponent';
import { useState, useEffect } from 'react';
import BarLoader from "react-spinners/BarLoader";
import axios from 'axios';
import { ROOT_DOMAIN_API } from '../../Constant';

function SiteStateComponent(props) {
    const [topSiteState, setTopSiteState] = useState([]);
    const [perfDate, setPerfDate] = useState(["J-0", "J-3", "J-2", "J-1"]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
              const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_siteState");
              const obj = await resp.data;
              if(obj.status&&obj.results){
                setTopSiteState(obj.results.result);
                setPerfDate(obj.results.all_date);
                setLoading(false);
              }
            } catch (error) {
                console.error(error);
            }
          })();
    }, [])

    return (
        <div className={"SiteState " + props.childClass}>
            <h2 className="MediumFont">Top sites state</h2>
            <div>
                <table>
                <thead>
                    <tr>
                    <th>URL & SSP</th>
                    <th>Site ID</th>
                    <th>{perfDate[1]}</th>
                    <th>{perfDate[2]}</th>
                    <th>{perfDate[3]}</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody className="SmallFont">
                    {topSiteState.map(site => {
                        return <SiteStateRowComponent key={site.site_id} url={site.site_true_url} sspAmount={site.nb_ssp}  siteID={site.site_id}  perfPrev3={site.date[1]} perfPrev2={site.date[2]} perfPrev1={site.date[3]}/>
                    })}
                </tbody>
                </table>
                <div className="FlexRow JustifyCenter">
                    <BarLoader className="MT20" color="#ababab" height="10px" width="30%" loading={loading}/>
                </div>
            </div>
        </div>
    );
}

export default SiteStateComponent;
