import './SiteStateRowComponent.css'

function SiteStateRowComponent(props) {
  return (
    <tr className="SiteStateRow">
        <td>
            <h4 className="M0"><a target="blank" className="ActionLink" href={props.url}>{props.url}</a></h4>
            <h6 className="M0">SSP currently connected: {props.sspAmount}</h6>
        </td>
        <td><a target="blank" className="ActionLink" href={'https://bo.themoneytizer.com/options/'+props.siteID}>{props.siteID}</a></td>
        <td>{props.perfPrev3.revenue}€&nbsp;<span>(<span className={String(props.perfPrev3.percentage).includes("-") ? "TRed" : "TGreen"}>{props.perfPrev3.percentage}%</span>)</span></td>
        <td>{props.perfPrev2.revenue}€&nbsp;<span>(<span className={String(props.perfPrev2.percentage).includes("-") ? "TRed" : "TGreen"}>{props.perfPrev2.percentage}%</span>)</span></td>
        <td>{props.perfPrev1.revenue}€&nbsp;<span>(<span className={String(props.perfPrev1.percentage).includes("-") ? "TRed" : "TGreen"}>{props.perfPrev1.percentage}%</span>)</span></td>
        <td>
            <a target="blank" href={'https://bo.themoneytizer.com/statistics/'+props.siteID} className="ActionButton BBlue"><i className="fa-solid fa-chart-column"></i></a>
            <a target="blank" href={'https://bo.themoneytizer.com/options/'+props.siteID} className="ActionButton BOrange ML5"><i className="fa-solid fa-code"></i></a>
        </td>
    </tr>
  );
}

export default SiteStateRowComponent;
