import { useEffect, useState } from 'react';
import './TimelineComponent.css';
import TimeLineRowComponent from '../TimeLineRowComponent/TimeLineRowComponent';
import BarLoader from "react-spinners/BarLoader";
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../Constant";

function TimelineComponent(props) {

    const [listNotifTimeline, setListNotifTimeline] = useState([]);
    const [loading, setLoading] = useState(true);
    const [timelineFilter, setTimelineFilter] = useState('');
    const colorList = {"freshdesk": "BRed", "modification_profile": "BYellow", "sendinblue": "BBlue", "modification_paiement": "BOrange"};
    const iconList = {"freshdesk": "fa-regular fa-comments", "modification_profile": "fa-solid fa-user", "sendinblue": "fa-regular fa-paper-plane", "modification_paiement": "fa-solid fa-piggy-bank" }

    useEffect(() => {
      if(!props.userID){
        return;
      }
      (async () => {
          try {
            const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getTimeline"+timelineFilter, {user_id: props.userID})
            const obj = await resp.data;
            setLoading(false);
            setListNotifTimeline(obj.results);
          } catch (error) {
              console.error(error);
          }
      })();
    }, [props.userID, timelineFilter])

    const changeFilter = function(filter){
      setTimelineFilter("?filter="+filter);
      setListNotifTimeline([]);
      setLoading(true);
    }
  return (
    <div className={"Timeline " + props.childClass}>
        <button onClick={() => {changeFilter("modification_paiement")}} className="Button1 ActionButton BOrange"><i className="fa-regular fa-envelope"></i> Paiement modifications</button>
        <button onClick={() => {changeFilter("modification_profile")}} className="Button2 ActionButton BYellow"><i className="fa-regular fa-envelope"></i> Profile Modifications</button>
        <button onClick={() => {changeFilter("freshdesk")}} className="Button3 ActionButton BRed"><i className="fa-regular fa-comments"></i> Freshdesk</button>
        <button onClick={() => {changeFilter("sendinblue")}} className="Button4 ActionButton BBlue"><i className="fa-regular fa-paper-plane"></i> Sendinblue</button>
        <button onClick={() => {changeFilter("all")}} className="Button5 ActionButton BGreen FullWidth"><i className="fa-solid fa-check"></i> Everything</button>
        <div className="Container1">
          <BarLoader color="#dbdbdb" height="10px" width="100%" loading={loading}/>
          {listNotifTimeline.map((row, index) => {
            return <TimeLineRowComponent key={index} from={row.from} link={row.link} html={row.htmlContent} icon={iconList[row.type]} name={row.name} text={row.type} color={colorList[row.type]} title={row.subject} date={row.date}/>
          })}
        </div>
    </div>
  );
}
  
export default TimelineComponent;