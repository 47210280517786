import './LoginComponent.css';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ROOT_DOMAIN_API } from '../../Constant';
axios.defaults.withCredentials = true;

function LoginComponent() {
    if(window.location.href !== "https://www.themoneytizer.com/crm/login"&&!localStorage.getItem('crm_redirection')){
        localStorage.setItem('crm_redirection', true);
        window.location.href = "https://www.themoneytizer.com/crm/login";
    }
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleUsername = event => {
        setUsername(event.target.value);
    };
    
    const handlePassword = event => {
        setPassword(event.target.value);
    };

    const handleKeyPress = event => {
        if(event.key === 'Enter'){
          sendAuth();
        }
      }

    const sendAuth = function(){
        (async () => {
            try {
                const resp = await axios.post(ROOT_DOMAIN_API+'/manage/v1/crm_user/crm_login', {username: username, password: password});
                const obj = await resp.data;
                if(obj.message==="Success."){
                    navigate('/', { replace: true });
                } else {
                    toast.error('Invalid credentials.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                }
            }catch(error){
                console.error(error); 
            }
        })();
        return false;
    }

    

  return (
    <div className="loginComponent">
        <ToastContainer
          theme="colored"
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
        <div className="FlexCol FormContainer">
            <h2>SIGN IN TO CONTINUE.</h2>
            <div className="InputContainer">
                <input type="text" onKeyPress={handleKeyPress} placeholder="Username" onChange={handleUsername} value={username}/>
            </div>
            <div className="InputContainer">
                <input type="password" onKeyPress={handleKeyPress} placeholder="Password" onChange={handlePassword} value={password}/>
            </div>
            <button type="submit" onClick={sendAuth} className="ActionButton ML20">Login</button>
        </div>
    </div>
  );
}

export default LoginComponent;
