import './HeadLineExportComponent.css';
import 'react-toastify/dist/ReactToastify.css';
import SearchFormUserSiteComponent from "../SearchFormUserSiteComponent/SearchFormUserSiteComponent"

function HeadLineExportComponent(props) {

  return (
    <div className={"HeadlineExport " + props.childClass}>
      <div className="FlexRow JustifyBetween">
        <h1 className="M0 MR5">{props.title}</h1>
          <SearchFormUserSiteComponent/>
      </div>
    </div>
  );
}
  
export default HeadLineExportComponent;
  