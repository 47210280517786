import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import './FreshdeskDateSatisfactionAndTagComponent.css';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { ROOT_DOMAIN_API } from "../../Constant";

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement);

function FreshdeskDateSatisfactionAndTagComponent(props) {
    const [graphDataSatisfaction, setGraphDataSatisfaction] = useState(null);
    const [graphDataTag, setGraphDataTag] = useState(null);
    const [options, setOptions] = useState(null);
    const [total, setTotal] = useState(null);
    const now = new Date();
    const [selectedRange, setSelectedRange] = useState({from: new Date(now.getFullYear(), now.getMonth(), 1), to: new Date()});
    const [dayPickerVisibility, setDayPickerVisibility] = useState(false);
    const toggleDayPickerVisibility = function(){
        if(dayPickerVisibility){
            setDayPickerVisibility(false);
        } else {
            setDayPickerVisibility(true);
        }
    }

    useEffect(() => {
        let dataAxios = {}
        dataAxios.dateFrom = format(selectedRange.from, 'yyyy-MM-dd');
        dataAxios.dateTo = format(selectedRange.to, 'yyyy-MM-dd');

        (async () => {
          try {
            const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getAllTicketsTags", dataAxios);
            const obj = await resp.data;
            if(obj.results!=null){
              var labelList = [];
              var amountList = [];
              obj.results.forEach((row, index) => {
                if(!labelList.includes(row.name)){
                  labelList.push(row.name);
                  amountList.push(row.tag_amount);
                }
              })
    
              const dataGraph = {
                labels: labelList,
                datasets: [
                  {
                    label: '# of Votes',
                    data: amountList,
                    backgroundColor: [
                      '#FFC312',
                      '#C4E538',
                      '#12CBC4',
                      '#FDA7DF',
                      '#ED4C67',
                      '#F79F1F',
                      '#A3CB38',
                      '#1289A7',
                      '#D980FA',
                      '#B53471',
                      '#EE5A24',
                      '#009432',
                      '#0652DD',
                      '#9980FA',
                      '#833471',
                      '#EA2027'
                    ],
                    borderColor: [
                      '#FFC312',
                      '#C4E538',
                      '#12CBC4',
                      '#FDA7DF',
                      '#ED4C67',
                      '#F79F1F',
                      '#A3CB38',
                      '#1289A7',
                      '#D980FA',
                      '#B53471',
                      '#EE5A24',
                      '#009432',
                      '#0652DD',
                      '#9980FA',
                      '#833471',
                      '#EA2027'
                    ],
                    borderWidth: 1,
                  },
                ],
              };
    
              setGraphDataTag(dataGraph);
    
            }
          } catch (error) {
              console.error(error);
          }
        })();

        (async () => {
            try {
              const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/crm_user/crm_getAllFreshdeskSatisfaction", dataAxios);
              const obj = await resp.data;
      
              var labelList = [];
              var datasets = [];
      
              if(obj.results!=null){
                obj.results.foreach((row, key) => {
                  labelList.push(row.label);
                  datasets.push(row.data);
                });
      
                const options = {
                  indexAxis: 'y',
                  elements: {
                    bar: {
                      borderWidth: 1,
                    },
                  },
                  responsive: true,
                  plugins: {
                      legend: {
                        position: 'none',
                      },
                    },
                };
      
                setOptions(options);
      
                var total = obj.count;
      
                setTotal(total);
      
                const labels = labelList;
                var dataGraph = {
                  labels,
                  datasets:[
                        {
                            label: 'Total',
                            data: datasets,
                            backgroundColor: '#f41127',
                        }
                    ],
                  };
      
                setGraphDataSatisfaction(dataGraph);
              }
      
            } catch (error) {
                console.error(error);
            }
          })();
      },[selectedRange]);
    
    return (
        <div className={"FreshdeskDateSatisfactionAndTag TCenter " + props.childClass}>
            <button className="ActionButton BRed" onClick={() => toggleDayPickerVisibility()}>
                <i className="fa-solid fa-calendar"></i>
                &nbsp;{format(selectedRange.from, 'yyyy-MM-dd')}
                &nbsp;<i className="fa-solid fa-arrow-right"></i>
                &nbsp;{format(selectedRange.to, 'yyyy-MM-dd')}
            </button>
            <DayPicker
            className={"DayPickerObject " + (dayPickerVisibility ? '' : 'Hide')}
            mode="range"
            selected={selectedRange}
            onSelect={setSelectedRange}
            />

            <div className={"FreshdeskSatisfaction MB20"}>
                <h2>Freshdesk Satisfaction ({total})</h2>
                {graphDataSatisfaction !== null ? <Bar options={options} data={graphDataSatisfaction} /> : ''}
            </div>

            <div className={"FreshdeskTag"}>
                <h2>Freshdesk tag distribution</h2>
                {graphDataTag !== null ? <Pie data={graphDataTag} /> : ''}
            </div>
        </div>
    );
}

export default FreshdeskDateSatisfactionAndTagComponent;